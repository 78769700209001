import { createJSONStorage } from "jotai/utils";

// Jotai weirdly doesn't export this or give you access to its generic properties
export type Storage<Value> = {
  getItem: (key: string) => Value | Promise<Value>;
  setItem: (key: string, newValue: Value) => void | Promise<void>;
  delayInit?: boolean;
  subscribe?: (key: string, callback: (value: Value) => void) => () => void;
};

export const atomSessionStorage = createJSONStorage(() => ({
  getItem: (key) => {
    const strVal = sessionStorage.getItem(key);
    if (!strVal) return undefined;
    return JSON.parse(strVal);
  },
  setItem: (key, value) => sessionStorage.setItem(key, JSON.stringify(value)),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
})) as Storage<any>;
export const SUPPORT_EMAIL = "support@reclaim.ai";
